.loading-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: 70vh; */
}

.inner-loading-data{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.loader-image{
    width: 200px;
    height: 200px;
    animation: slide-in-left 0.8s;
}
