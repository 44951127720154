:root {
  --primary-color: #6d7993;
  /* --secondary-color: #96858f; */
  --red-shade-color: #934a5f;
  --secondary-color: #6b5759;
  --ternary-color: #9099a2;
  --text-color: #576176;
  --shadow-1: 25px 25px 50px #b5b5b5;
  --shadow-2: -25px -25px 50px #f5f5f5;
  --background-card-color: #ecf0f3;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9e9fa791;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9e9fa791;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9e9fa791;
}

h1 {
  margin: 0;
}

body {
  font-family: "Poppins", sans-serif, "Quicksand" !important;
}

.App {
  background: #e8e8e8;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: geometricPrecision;
}

/* Global Styles used */

.our-button {
  background: var(--secondary-color);
  border: var(--secondary-color);
}

.our-button:hover {
  background: var(--secondary-color) !important;
}

.p-button-outlined {
  color: #d5d5d5 !important;
}

.primary-colour {
  color: var(--primary-color);
}

.secondary-colour {
  color: var(--secondary-color);
}

.red-shade-colour {
  color: var(--red-shade-color);
}

.ternary-colour {
  color: var(--ternary-color);
}

.text-colour {
  color: var(--text-color);
}

.text-grey {
  color: #6b6b6b;
}

.text-light-grey {
  color: #9e9fa7;
}

.text-white-light {
  color: #e6e6e6;
}

.text-black {
  color: #272727;
}

.text-green {
  color: #1fc264;
}

.text-red {
  color: #ff4c41;
}

.text-blue {
  color: #51a6f5;
}

.text-yellow {
  color: #ffae00;
}

.pointer {
  cursor: pointer;
}

.pointer-disabled {
  /* cursor: no-drop; */
  cursor: not-allowed;
}

.primary-header-text {
  font-size: 45px;
  font-weight: 700;
}

.primary-font {
  font-family: "Quicksand";
}

.primary-font-2 {
  font-family: "Raleway";
}

.landing-description-text {
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
}

.larger-text {
  font-size: 30px;
}

.mid-large-text {
  font-size: 25px;
}

.large-text {
  font-size: 20px;
}

.medium-text {
  font-size: 16px;
}

.small-text {
  font-size: 14px;
}

.very-small-text {
  font-size: 12px;
}

.bold {
  font-weight: 600;
}

.bolder {
  font-weight: 700;
}

.boldest {
  font-weight: 800;
}

.flex-small-gap {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.align-center {
  align-items: center;
}

.flex-gap {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.red-icon {
  font-size: 22px;
  transition: 0.5s;
}

.red-icon:hover {
  color: red;
}

.small-padding-bottom {
  padding-bottom: 1rem;
}

.small-margin-top {
  margin-top: 1rem;
}

.small-margin-bottom {
  margin-bottom: 1rem;
}

.medium-margin-top {
  margin-top: 1.5rem;
}

.small-margin {
  margin: 0.5rem;
}

.normal-margin {
  margin: 1rem;
}

.copyright {
  font-size: 16px;
  font-weight: 700;
  padding: 1.5rem 2rem;
  bottom: 0;
  text-align: center;
}

.just-flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  gap: 0.5rem;
}

.medium-gap {
  gap: 1rem;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
}

.horizontal-line {
  width: 60px;
  height: 5px;
  background-color: var(--red-shade-color);
}

.flex-small-gap-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.flex-gap-column-1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flex-gap-column-2 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.flex-gap-column {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.small-padding {
  padding: 1rem;
}

.medium-padding {
  padding: 2rem;
}

.mantine-Select-itemsWrapper {
  width: auto !important;
}

.mantine-MultiSelect-itemsWrapper {
  width: auto !important;
}

.mantine-Input-input {
  border-radius: 1rem;
  background: #e8e8e8;
  box-shadow: inset 9px 9px 18px #c5c5c5, inset -9px -9px 18px #ffffff;
  transition: 0.3s;
  font-weight: 600;
  font-size: 16px;
  background: #e8e8e8 !important;
  color: var(--text-color);
}

.mantine-PasswordInput-innerInput {
  font-weight: 600;
}

.mantine-Modal-modal {
  background-color: #e8e8e8 !important;
}

.mantine-Drawer-drawer {
  background-color: #e8e8e8 !important;
}

.small-card {
  padding: 1rem;
  border-radius: 20px;
}

.link {
  font-weight: 700;
  color: var(--secondary-color) !important;
  cursor: pointer;
  transition: 0.3s;
}

.link:hover {
  text-decoration: underline;
}

.link--active {
  font-weight: 700;
  color: var(--red-shade-color) !important;
}

.top-heading-admin-wrapper {
  display: flex;
  justify-content: center;
}

.top-heading-admin {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0.5rem 0;
  width: 95vw;
}

.form-main-login {
  min-width: 350px;
}

/* Custom Scrollbar */

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary-color) #d5d5d5;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #d5d5d5;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 20px;
  border: 3px solid var(--secondary-color);
}

/* Custom Animations */

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@media screen and (max-width: 550px) {
  .description-text {
    text-align: center;
  }

  .flex-small-gap {
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .form-main-login {
    min-width: 320px;
  }
}
